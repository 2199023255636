const scrollAnim = function () {
  const contentTilteList = [...document.querySelectorAll(`.content-title`)];
  const titleObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add(`--smoothInSkew`);
          titleObserver.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: `-20% 0px`,
      threshold: 0,
    },
  );

  const contentLeadList = [...document.querySelectorAll(`.content-lead`)];
  const leadObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add(`--smoothIn`);
          leadObserver.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: `-20% 0px`,
      threshold: 0,
    },
  );

  const newsList = [...document.querySelectorAll(`.news-item`)];
  const newsObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add(`--borderBottom`);
          newsObserver.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: `-20% 0px`,
      threshold: 0,
    },
  );

  const serviceTopList = [...document.querySelectorAll(`.service-section-inner-panel-content`)];
  const serviceTopObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add(`--smoothIn`);
          newsObserver.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: `-10% 0px`,
      threshold: 0,
    },
  );

  const serviceTopBgList = [...document.querySelectorAll(`.service-section-bg-img__wrap`)];
  const serviceTopBgObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add(`--bgSlideIn`);
          newsObserver.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: `-5% 0px`,
      threshold: 0,
    },
  );

  contentTilteList.forEach((contentTilteItem) => {
    titleObserver.observe(contentTilteItem);
  });

  contentLeadList.forEach((contentLeadItem) => {
    leadObserver.observe(contentLeadItem);
  });

  newsList.forEach((newsListItem) => {
    newsObserver.observe(newsListItem);
  });

  serviceTopList.forEach((serviceTopListItem) => {
    serviceTopObserver.observe(serviceTopListItem);
  });

  serviceTopBgList.forEach((serviceTopBgListItem) => {
    serviceTopBgObserver.observe(serviceTopBgListItem);
  });
};

export default scrollAnim;

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import SEO from '@/components/seo';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
// import serviceTop from '@/components/Interaction/service-top';
import scrollAnim from '@/components/Interaction/scrollAnim';
import hoverCircle from '@/components/Interaction/hoverCircle';
import { Context } from '@/components/Layout';

import * as pageHeaderCategory from '@/styles/object/page-header-category.module.scss';
import * as textLead from '@/styles/object/text-lead.module.scss';

const ServiceIndex: React.FC = () => {
  const loading = useContext(Context);
  useEffect(() => {
    // serviceTop();
    hoverCircle();
    scrollAnim();
  }, []);

  return (
    !loading && (
      <>
        <SEO title="サービス" />
        <div className="page-wrap --serviceTop" id="serviceTop">
          <header className={pageHeaderCategory.page_header_category}>
            <h1>
              <small
                className={pageHeaderCategory.page_header_category__head_text}
              >
                事業・サービス
              </small>
              <div className={pageHeaderCategory.page_header_category__text}>
                Service
              </div>
            </h1>
            <p
              className={`${pageHeaderCategory.page_header_category__lead} ${textLead.text_lead}`}
            >
              NRIネットコムは、異なる専門性を持った4つの事業領域により、
              <br className="d-pc-inline" />
              デジタル戦略の実現を全方位でサポートし、お客さまのビジネスの継続的な発展に貢献します。
            </p>
          </header>
          <section className="service-section" data-service-section="0">
            <div className="service-section-inner">
              <div className="service-section-inner-panel">
                <div className="service-section-bg">
                  <div className="service-section-bg-img__wrap">
                    <div className="service-section-bg-img --web-solution" />
                  </div>
                  <div className="service-section-bg__wave-top" />
                  <div className="service-section-bg__wave-bottom" />
                </div>
                <div className="service-section-inner-panel-content">
                  <div className="service-section-inner-panel-content__sub">
                    <h3 className="title-lv2-01">
                      <span className="title-lv2-01__head">
                        Webソリューション事業
                      </span>
                      <span className="title-lv2-01__text">Web Solution</span>
                    </h3>
                    <section className="service-summary">
                      <h4 className="service-summary__title">
                        先端のテクノロジーやサービスで
                        <br className="d-pc-inline" />
                        お客さまのビジネスを支えます
                      </h4>
                      <p className="service-summary__text">
                        さまざまな業種のお客さまに対してWebシステムを構築・運用する事業です。クラウド、モバイル、モダン開発などの技術を組み合わせ顧客課題の解決に導きます。
                      </p>
                      <ul className="service-summary__list list-rect">
                        <li className="list-rect__item">
                          Webシステム設計・開発・運用
                        </li>
                        <li className="list-rect__item">
                          会員サービス向けシステム開発
                        </li>
                        <li className="list-rect__item">業務システム開発</li>
                        <li className="list-rect__item">
                          AWSなどクラウド導入支援
                        </li>
                        <li className="list-rect__item">
                          モバイルアプリの企画・開発
                        </li>
                      </ul>
                      <div className="service-summary__link">
                        <Link
                          to="/service/web-solution/"
                          className="service-summary-link-inner js-hover-circle"
                          data-circle-type="stick"
                          data-circle-inner="arrow-right"
                        >
                          View More
                        </Link>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div className="service-section-inner-panel">
                <div className="service-section-bg">
                  <div className="service-section-bg-img__wrap">
                    <div className="service-section-bg-img --web-creative" />
                  </div>
                  <div className="service-section-bg__wave-top" />
                  <div className="service-section-bg__wave-bottom" />
                </div>
                <div className="service-section-inner-panel-content">
                  <div className="service-section-inner-panel-content__sub">
                    <h3 className="title-lv2-01">
                      <span className="title-lv2-01__head">
                        Webクリエイティブ事業
                      </span>
                      <span className="title-lv2-01__text">Web Creative</span>
                    </h3>
                    <section className="service-summary">
                      <h4 className="service-summary__title">
                        お客さまの「伝えたい」を表現し、
                        <br className="d-pc-inline" />
                        成果につなげるクリエイティブを
                      </h4>
                      <p className="service-summary__text">
                        お客さまの課題解決に向けたクリエイティブ制作を行う事業です。現状の分析から戦略立案、UX/UIデザイン、各種ビジュアル制作、Webサイト構築までトータルでご支援します。
                      </p>
                      <ul className="service-summary__list list-rect">
                        <li className="list-rect__item">
                          Webサイト構築
                        </li>
                        <li className="list-rect__item">
                          UX/UIデザイン
                        </li>
                        <li className="list-rect__item">
                          Webコンサルティング
                        </li>
                        <li className="list-rect__item">
                          ブランディング戦略
                        </li>
                        <li className="list-rect__item">
                          コンテンツ企画・制作
                        </li>
                        <li className="list-rect__item">
                          Webサイト運用
                        </li>
                      </ul>
                      <div className="service-summary__link">
                        <Link
                          to="/service/web-creative/"
                          className="service-summary-link-inner js-hover-circle"
                          data-circle-type="stick"
                          data-circle-inner="arrow-right"
                        >
                          View More
                        </Link>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div className="service-section-inner-panel">
                <div className="service-section-bg">
                  <div className="service-section-bg-img__wrap">
                    <div className="service-section-bg-img --digital-marketing" />
                  </div>
                  <div className="service-section-bg__wave-top" />
                  <div className="service-section-bg__wave-bottom" />
                </div>
                <div className="service-section-inner-panel-content">
                  <div className="service-section-inner-panel-content__sub">
                    <h3 className="title-lv2-01">
                      <span className="title-lv2-01__head">
                        デジタルマーケティング事業
                      </span>
                      <span className="title-lv2-01__text">
                        Digital Marketing
                      </span>
                    </h3>
                    <section className="service-summary">
                      <h4 className="service-summary__title">
                        テクノロジーとマーケティングスキルの
                        <br className="d-pc-inline" />
                        融合でビジネスの発展に貢献
                      </h4>
                      <p className="service-summary__text">
                        多様なデバイスを通じて収集したデータの解析を行う事業です。
                        <br />
                        高度な分析からデジタル広告まで、さまざまな側面からお客さまのマーケティング活動をご支援しています。
                      </p>
                      <ul className="service-summary__list list-rect">
                        <li className="list-rect__item">
                          テクノロジーを軸としたマーケティング戦略立案
                        </li>
                        <li className="list-rect__item">
                          Google Cloud
                        </li>
                        <li className="list-rect__item">
                          デジタル広告サービス
                        </li>
                        <li className="list-rect__item">
                          BtoB、BtoCマーケティング支援
                        </li>
                        <li className="list-rect__item">MA導入・運用支援</li>
                      </ul>
                      <div className="service-summary__link">
                        <Link
                          to="/service/digital-marketing/"
                          className="service-summary-link-inner js-hover-circle"
                          data-circle-type="stick"
                          data-circle-inner="arrow-right"
                        >
                          View More
                        </Link>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div className="service-section-inner-panel">
                <div className="service-section-bg">
                  <div className="service-section-bg-img__wrap">
                    <div className="service-section-bg-img --cloud-solution" />
                  </div>
                  <div className="service-section-bg__wave-top" />
                  <div className="service-section-bg__wave-bottom" />
                </div>
                <div className="service-section-inner-panel-content">
                  <div className="service-section-inner-panel-content__sub">
                    <h3 className="title-lv2-01">
                      <span className="title-lv2-01__head">
                        クラウドソリューション事業
                      </span>
                      <span className="title-lv2-01__text">
                        Cloud Solution
                      </span>
                    </h3>
                    <section className="service-summary">
                      <h4 className="service-summary__title">
                        技術とコンサルティング力の融合で
                        <br className="d-pc-inline" />
                        お客さまのビジネスを成功へと導きます
                      </h4>
                      <p className="service-summary__text">
                        お客さまの目的や課題に対して最適なクラウドアーキテクチャを提供する事業です。経験豊富なクラウドサービスのスペシャリストが、システム構築や改善のアドバイスを行います。
                      </p>
                      <ul className="service-summary__list list-rect">
                        <li className="list-rect__item">
                          クラウド最適化コンサルティング
                        </li>
                        <li className="list-rect__item">
                          AWS Well-Architected診断サービス
                        </li>
                        <li className="list-rect__item">
                          内製化支援サービス
                        </li>
                        <li className="list-rect__item">
                          データ分析基盤構築・運用サービス
                        </li>
                        <li className="list-rect__item">
                          AWS請求代行・AWSアカウント管理サービス
                        </li>
                      </ul>
                      <div className="service-summary__link">
                        <Link
                          to="/service/cloud-solution/"
                          className="service-summary-link-inner js-hover-circle"
                          data-circle-type="stick"
                          data-circle-inner="arrow-right"
                        >
                          View More
                        </Link>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="page-contents-area-01">
            <aside className="service-bunner" />
          </div>
        </div>
        <UIBreadcrumb UpperItem={[]} CurrentItem="Service" />
      </>
    )
  );
};

export default ServiceIndex;
